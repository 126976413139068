import React, { useEffect, useLayoutEffect, useState } from "react";
import { SapienPageProps } from "@/inertia-utils/types";
import {
    ModelBlock,
    Round,
    Simulation,
    TimeHorizon,
    VariableDepthObject,
    VariableRelationship,
} from "@/models";
import { TopNavLinkDiv } from "@/components";
import { Link as InertiaLink } from "@inertiajs/react";
import route from "ziggy-js";
import {
    TimeHorizonList,
    ModelBlockList,
    ModelVariableForm,
    QuestionList,
    ModelTrees,
    QuestionPanel,
    NestedDataList,
    VariableOrganization,
    VariablePanel,
    QuestionSubmoduleForm,
    ModelBuilderDashboard,
    BuilderWorkspace,
    SubmodulePanel,
    QuestionSubmoduleData,
    InteractiveBlockList,
} from "@/Layouts/builder";
import {
    useBuilderWorkspaceStore,
    useModelBlockAccordionState,
    useModelBuilderStore,
} from "@/hooks/store";
import { UserGuide } from "@/Layouts/builder/UserGuide";
import LogoImage from "@/components/LogoImage";
import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    InformationCircleIcon,
    Squares2X2Icon,
    Cog6ToothIcon,
    MapIcon,
    CubeIcon,
    GlobeAltIcon,
    HomeIcon,
    ScaleIcon,
    PuzzlePieceIcon,
    RocketLaunchIcon,
} from "@heroicons/react/24/outline";
import { useSelectedSimulation } from "@/hooks";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";
// import { useModelBuilderPage } from "@/inertia-utils/hooks";

type Props = SapienPageProps & {
    simulation: Simulation & { is_course: boolean };
    timeHorizons: TimeHorizon[];
    modelBlocks: ModelBlock[];
    rounds: Round[];
    variableRelationships: VariableRelationship[];
    variableDepths: VariableDepthObject[];
    type?: string;
    interactiveBlockId?: string;
    interactiveBlocks?: ModelBlock[];
};

const breakpoint = 996;
const modelBuilderSizes = {
    toolbar: "36px",
    leftMenu: "330px",
    rightMenu: "330px",
};
const modelBuilderColors = {
    bgTop: "#15171a",
    bgMenu: "#2d2f31",
    bgPanel: "#1f1f1f",
    menuColor: "white",
};

const rightMenuItemObject = {
    workspace: <Cog6ToothIcon className="h-5 w-5" />,
};
const leftMenuItemObject = {
    dashboard: <HomeIcon className="h-5 w-5" />,
    design: <Squares2X2Icon className="h-5 w-5" />,
    structure: <CubeIcon className="h-5 w-5" />,
    connections: <MapIcon className="h-5 w-5" />,
    submodules: <GlobeAltIcon className="h-5 w-5" />,
};

export default function ModelBuilder({
    simulation,
    modelBlocks,
    variableRelationships,
    type,
    interactiveBlockId,
    interactiveBlocks,
}: Props) {
    const { gotSimulation, selectedSimulation } = useSelectedSimulation();
    useEffect(() => {
        if (!selectedSimulation || selectedSimulation.id !== simulation.id) {
            gotSimulation(simulation);
        }
    }, [simulation]);

    const { gotModelBlocks, gotVariableRelationships } = useModelBuilderStore();
    const {
        toggleModelBlockAccordionKey,
        expandMultipleModelBlockAccordionKeys,
        collapseMultipleModelBlockAccordionKeys,
        getIsModelBlockExpanded,
    } = useModelBlockAccordionState();
    const { sourceVariables, targetVariable } = useBuilderWorkspaceStore();

    const [section, setSection] = useState<string>("design");
    const [isLeftMenuOpen, setIsLeftMenuOpen] = useState<boolean>(true);
    const [isRightMenuOpen, setIsRightMenuOpen] = useState<boolean>(false);
    const [leftMenuSection, setLeftMenuSection] = useState<string>("design");
    const [rightMenuSection, setRightMenuSection] =
        useState<string>("workspace");

    const [selectedBuilderRoundId, setSelectedBuilderRoundId] =
        useState<string>("");

    const [questionSubmoduleData, setQuestionSubmoduleData] =
        useState<QuestionSubmoduleData>();

    useEffect(() => {
        gotModelBlocks(modelBlocks || []);
    }, [modelBlocks]);

    useEffect(() => {
        gotVariableRelationships(variableRelationships || []);
    }, [variableRelationships]);

    useEffect(() => {
        if (!!sourceVariables && !!Object.keys(sourceVariables).length) {
            expandMultipleModelBlockAccordionKeys(
                Object.values(sourceVariables).map(
                    (sourceVariable) => sourceVariable.model_block_id,
                ),
            );
        }
    }, [sourceVariables]);

    useEffect(() => {
        if (
            ((!!sourceVariables && !!Object.keys(sourceVariables).length) ||
                !!targetVariable) &&
            !isRightMenuOpen
        ) {
            setIsRightMenuOpen(true);
        }
    }, [sourceVariables, targetVariable]);

    const [exceedsBreakpoint, setExceedsBreakpoint] = useState<boolean>(false);

    useLayoutEffect(() => {
        setExceedsBreakpoint(window.innerWidth > breakpoint);

        const handleResize = () => {
            setExceedsBreakpoint(window.innerWidth > breakpoint);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [breakpoint]);

    useLayoutEffect(() => {
        setIsLeftMenuOpen(exceedsBreakpoint);
        // setIsRightMenuOpen(exceedsBreakpoint);
        if (isRightMenuOpen) {
            setIsRightMenuOpen(exceedsBreakpoint);
        }
    }, [exceedsBreakpoint]);

    return (
        simulation !== undefined && (
            <>
                <div
                    className="model-builder-container"
                    style={{
                        display: "flex",
                        height: "100vh",
                        flexDirection: "column",
                        overflow: "hidden",
                        position: "relative",
                    }}
                >
                    <div
                        className="model-builder-top"
                        style={{
                            display: "flex",
                            height: modelBuilderSizes.toolbar,
                            flexDirection: "row",
                        }}
                    >
                        <div
                            className="model-builder-top-logo"
                            style={{
                                width: modelBuilderSizes.toolbar,
                                minWidth: modelBuilderSizes.toolbar,
                                height: modelBuilderSizes.toolbar,
                                background: modelBuilderColors.bgMenu,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <TopNavLinkDiv style={{ padding: 0 }}>
                                <InertiaLink
                                    href={
                                        !!simulation &&
                                        route("admin.projects.show", {
                                            sim: simulation.slug,
                                        })
                                    }
                                >
                                    <LogoImage size={"22"} opacity={"0.9"} />
                                </InertiaLink>
                            </TopNavLinkDiv>
                        </div>
                        <div
                            className="model-builder-top-center"
                            style={{
                                width: `calc(100% - ${modelBuilderSizes.toolbar})`,
                                minWidth: `calc(100% - ${modelBuilderSizes.toolbar})`,
                                height: modelBuilderSizes.toolbar,
                                background: modelBuilderColors.bgTop,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                color: modelBuilderColors.menuColor,
                                transition: "all .25s ease",
                            }}
                        >
                            <div className="px-3 text-sm">
                                <div className="flex items-center">
                                    {!type ? (
                                        <>
                                            <div className="font-bold">
                                                {`Model Builder`}
                                            </div>
                                            <TopNavLinkDiv
                                                style={{
                                                    padding: 0,
                                                }}
                                                className=""
                                            >
                                                <InertiaLink
                                                    href={
                                                        interactiveBlocks?.length >
                                                        0
                                                            ? route(
                                                                  "admin.projects.model-builder.interactive-detail",
                                                                  {
                                                                      simulationSlug:
                                                                          simulation.slug,
                                                                      type: "interactive",
                                                                      interactiveBlockId:
                                                                          interactiveBlocks[0]
                                                                              .id,
                                                                  },
                                                              )
                                                            : route(
                                                                  "admin.projects.model-builder.interactive",
                                                                  {
                                                                      simulationSlug:
                                                                          simulation.slug,
                                                                      type: "interactive",
                                                                  },
                                                              )
                                                    }
                                                >
                                                    <div className="px-3 font-light">
                                                        {`Interactive`}
                                                    </div>
                                                </InertiaLink>
                                            </TopNavLinkDiv>
                                        </>
                                    ) : (
                                        <>
                                            <TopNavLinkDiv
                                                style={{
                                                    padding: 0,
                                                }}
                                                className=""
                                            >
                                                <InertiaLink
                                                    href={route(
                                                        "admin.projects.model-builder",
                                                        {
                                                            simulationSlug:
                                                                simulation.slug,
                                                        },
                                                    )}
                                                >
                                                    <div className="font-bold">
                                                        {`Model Builder`}
                                                    </div>
                                                </InertiaLink>
                                            </TopNavLinkDiv>
                                            <TopNavLinkDiv
                                                style={{
                                                    padding: 0,
                                                }}
                                                className=""
                                            >
                                                <InertiaLink
                                                    href={route(
                                                        "admin.projects.model-builder.interactive",
                                                        {
                                                            simulationSlug:
                                                                simulation.slug,
                                                            type: "interactive",
                                                        },
                                                    )}
                                                >
                                                    <div className="px-3 font-light">
                                                        {`Interactive`}
                                                    </div>
                                                </InertiaLink>
                                            </TopNavLinkDiv>
                                            {interactiveBlocks?.length > 0 && (
                                                <div className="flex py-px">
                                                    <select
                                                        className="flex w-full items-center rounded-sm border-gray-600 bg-white/5 py-1 pl-2 pr-8
                                                            text-sm text-white focus:outline-none focus:ring-0"
                                                        value={
                                                            interactiveBlockId ||
                                                            ""
                                                        }
                                                        onChange={(e) => {
                                                            if (
                                                                e.target
                                                                    .value !==
                                                                ""
                                                            )
                                                                SapienInertia.visit(
                                                                    sapienRoute(
                                                                        "admin.projects.model-builder.interactive-detail",
                                                                        {
                                                                            simulationSlug:
                                                                                simulation.slug,
                                                                            type: "interactive",
                                                                            interactiveBlockId:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        },
                                                                    ),
                                                                );
                                                        }}
                                                        disabled={false}
                                                    >
                                                        <option
                                                            key={""}
                                                            value={""}
                                                            className="bg-[#29303e]"
                                                            disabled
                                                        >
                                                            {""}
                                                        </option>
                                                        {interactiveBlocks.map(
                                                            (block) => (
                                                                <option
                                                                    key={
                                                                        block.id
                                                                    }
                                                                    value={
                                                                        block.id
                                                                    }
                                                                    className="bg-[#29303e]"
                                                                >
                                                                    {
                                                                        block.label
                                                                    }
                                                                </option>
                                                            ),
                                                        )}
                                                    </select>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className="px-3 text-sm font-thin">
                                {`${simulation.title}`}
                            </div>
                            {(!type || !!interactiveBlockId) && (
                                <div
                                    className="model-builder-top-right-icon-menu"
                                    style={{
                                        height: "100%",
                                        background: modelBuilderColors.bgMenu,
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        color: modelBuilderColors.menuColor,
                                        transition: "all .25s ease",
                                    }}
                                >
                                    <div
                                        key={"toggle-right"}
                                        className="flex cursor-pointer items-center justify-center"
                                        style={{
                                            width: modelBuilderSizes.toolbar,
                                            height: modelBuilderSizes.toolbar,
                                        }}
                                        onClick={() => {
                                            if (
                                                !exceedsBreakpoint &&
                                                !isRightMenuOpen
                                            ) {
                                                setIsLeftMenuOpen(false);
                                            }
                                            setIsRightMenuOpen(
                                                !isRightMenuOpen,
                                            );
                                        }}
                                    >
                                        {isRightMenuOpen ? (
                                            <ChevronDoubleRightIcon className="h-5 w-5" />
                                        ) : (
                                            <ChevronDoubleLeftIcon className="h-5 w-5" />
                                        )}
                                    </div>

                                    {Object.keys(rightMenuItemObject).map(
                                        (key) => (
                                            <div
                                                key={key}
                                                className="flex cursor-pointer items-center justify-center"
                                                style={{
                                                    width: modelBuilderSizes.toolbar,
                                                    height: modelBuilderSizes.toolbar,
                                                    background:
                                                        rightMenuSection ===
                                                            key &&
                                                        isRightMenuOpen &&
                                                        "rgba(255,255,255,.15)",
                                                }}
                                                onClick={() => {
                                                    if (
                                                        isRightMenuOpen &&
                                                        rightMenuSection === key
                                                    ) {
                                                        setIsRightMenuOpen(
                                                            false,
                                                        );
                                                    } else {
                                                        setIsRightMenuOpen(
                                                            true,
                                                        );
                                                        setRightMenuSection(
                                                            key,
                                                        );
                                                        if (
                                                            !exceedsBreakpoint
                                                        ) {
                                                            setIsLeftMenuOpen(
                                                                false,
                                                            );
                                                        }
                                                    }
                                                }}
                                            >
                                                {rightMenuItemObject[key]}
                                            </div>
                                        ),
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {!type || !!interactiveBlockId ? (
                        <div
                            className="model-builder-bottom"
                            style={{
                                display: "flex",
                                height: `calc(100vh - ${modelBuilderSizes.toolbar})`,
                                flexDirection: "row",
                            }}
                        >
                            <div
                                className="model-builder-left-icon-menu"
                                style={{
                                    height: "100%",
                                    width: modelBuilderSizes.toolbar,
                                    minWidth: modelBuilderSizes.toolbar,
                                    background: modelBuilderColors.bgMenu,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    color: modelBuilderColors.menuColor,
                                    transition: "all .25s ease",
                                }}
                            >
                                <div>
                                    <div
                                        key={"toggle"}
                                        className="flex cursor-pointer items-center justify-center"
                                        style={{
                                            width: modelBuilderSizes.toolbar,
                                            height: modelBuilderSizes.toolbar,
                                        }}
                                        onClick={() => {
                                            if (
                                                !exceedsBreakpoint &&
                                                !isLeftMenuOpen
                                            ) {
                                                setIsRightMenuOpen(false);
                                            }
                                            setIsLeftMenuOpen(!isLeftMenuOpen);
                                        }}
                                    >
                                        {isLeftMenuOpen ? (
                                            <ChevronDoubleLeftIcon className="h-5 w-5" />
                                        ) : (
                                            <ChevronDoubleRightIcon className="h-5 w-5" />
                                        )}
                                    </div>
                                    {Object.keys(leftMenuItemObject)
                                        .filter(
                                            (key) =>
                                                !type ||
                                                (key !== "dashboard" &&
                                                    key !== "connections"),
                                        )
                                        .map((key) => (
                                            <div
                                                key={key}
                                                className="flex cursor-pointer items-center justify-center"
                                                style={{
                                                    width: modelBuilderSizes.toolbar,
                                                    height: modelBuilderSizes.toolbar,
                                                    background:
                                                        leftMenuSection ===
                                                            key &&
                                                        isLeftMenuOpen &&
                                                        "rgba(255,255,255,.15)",
                                                }}
                                                onClick={() => {
                                                    if (
                                                        isLeftMenuOpen &&
                                                        leftMenuSection === key
                                                    ) {
                                                        setIsLeftMenuOpen(
                                                            false,
                                                        );
                                                    } else {
                                                        setIsLeftMenuOpen(true);
                                                        setLeftMenuSection(key);
                                                        setSection(key);
                                                        if (
                                                            !exceedsBreakpoint
                                                        ) {
                                                            setIsRightMenuOpen(
                                                                false,
                                                            );
                                                        }
                                                    }
                                                }}
                                            >
                                                {leftMenuItemObject[key]}
                                            </div>
                                        ))}
                                </div>
                                {!type && (
                                    <div>
                                        <InertiaLink
                                            href={
                                                !!simulation &&
                                                route("interactive.index", {
                                                    simulationSlug:
                                                        simulation.slug,
                                                })
                                            }
                                        >
                                            <div
                                                key={"interactive"}
                                                className="flex cursor-pointer items-center justify-center"
                                                style={{
                                                    width: modelBuilderSizes.toolbar,
                                                    height: modelBuilderSizes.toolbar,
                                                    background:
                                                        leftMenuSection ===
                                                            "guide" &&
                                                        isLeftMenuOpen &&
                                                        "rgba(255,255,255,.15)",
                                                }}
                                            >
                                                <RocketLaunchIcon className="h-5 w-5" />
                                            </div>
                                        </InertiaLink>
                                        <InertiaLink
                                            href={
                                                !!simulation &&
                                                route("model-sandbox.index", {
                                                    simulationSlug:
                                                        simulation.slug,
                                                })
                                            }
                                        >
                                            <div
                                                key={"sandbox"}
                                                className="flex cursor-pointer items-center justify-center"
                                                style={{
                                                    width: modelBuilderSizes.toolbar,
                                                    height: modelBuilderSizes.toolbar,
                                                    background:
                                                        leftMenuSection ===
                                                            "guide" &&
                                                        isLeftMenuOpen &&
                                                        "rgba(255,255,255,.15)",
                                                }}
                                            >
                                                <PuzzlePieceIcon className="h-5 w-5" />
                                            </div>
                                        </InertiaLink>
                                        <InertiaLink
                                            href={
                                                !!simulation &&
                                                route(
                                                    "compare-variable-calculation-calls.index",
                                                    {
                                                        simulation:
                                                            simulation.id,
                                                    },
                                                )
                                            }
                                        >
                                            <div
                                                key={"comparison"}
                                                className="flex cursor-pointer items-center justify-center"
                                                style={{
                                                    width: modelBuilderSizes.toolbar,
                                                    height: modelBuilderSizes.toolbar,
                                                    background:
                                                        leftMenuSection ===
                                                            "guide" &&
                                                        isLeftMenuOpen &&
                                                        "rgba(255,255,255,.15)",
                                                }}
                                            >
                                                <ScaleIcon className="h-5 w-5" />
                                            </div>
                                        </InertiaLink>
                                        <div
                                            key={"guide"}
                                            className="flex cursor-pointer items-center justify-center"
                                            style={{
                                                width: modelBuilderSizes.toolbar,
                                                height: modelBuilderSizes.toolbar,
                                                background:
                                                    leftMenuSection ===
                                                        "guide" &&
                                                    isLeftMenuOpen &&
                                                    "rgba(255,255,255,.15)",
                                            }}
                                            onClick={() => {
                                                setIsLeftMenuOpen(true);
                                                setLeftMenuSection("guide");
                                            }}
                                        >
                                            <InformationCircleIcon className="h-5 w-5" />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div
                                className="model-builder-left-detail-menu"
                                style={{
                                    height: "100%",
                                    width: isLeftMenuOpen
                                        ? modelBuilderSizes.leftMenu
                                        : "0px",
                                    minWidth: isLeftMenuOpen
                                        ? modelBuilderSizes.leftMenu
                                        : "0px",
                                    background: modelBuilderColors.bgPanel,
                                    color: modelBuilderColors.menuColor,
                                    overflow: "auto",
                                    transition: "all .25s ease",
                                }}
                            >
                                {leftMenuSection === "dashboard" && (
                                    <div className="p-3">
                                        <div className="mb-3 text-xl">
                                            Overview
                                        </div>
                                        <TimeHorizonList
                                            simulationId={simulation.id}
                                        />
                                    </div>
                                )}
                                {leftMenuSection === "design" && (
                                    <div className="p-3">
                                        <div className="mb-3 text-xl">
                                            Design
                                        </div>
                                        <VariablePanel
                                            toggleModelBlockAccordionKey={
                                                toggleModelBlockAccordionKey
                                            }
                                            expandMultipleModelBlockAccordionKeys={
                                                expandMultipleModelBlockAccordionKeys
                                            }
                                            collapseMultipleModelBlockAccordionKeys={
                                                collapseMultipleModelBlockAccordionKeys
                                            }
                                            getIsModelBlockExpanded={
                                                getIsModelBlockExpanded
                                            }
                                        />
                                    </div>
                                )}
                                {leftMenuSection === "structure" && (
                                    <div className="p-3">
                                        <div className="mb-3 text-xl">
                                            Structure
                                        </div>
                                        <ModelBlockList
                                            simulationId={simulation.id}
                                            interactive={!!type}
                                        />
                                    </div>
                                )}
                                {leftMenuSection === "connections" && (
                                    <div className="p-3">
                                        <div className="mb-3 text-xl">
                                            Connections
                                        </div>
                                        <QuestionPanel
                                            selectedBuilderRoundId={
                                                selectedBuilderRoundId
                                            }
                                            setSelectedBuilderRoundId={
                                                setSelectedBuilderRoundId
                                            }
                                        />
                                    </div>
                                )}
                                {leftMenuSection === "submodules" && (
                                    <div className="p-3">
                                        <div className="mb-3 text-xl">
                                            Submodules
                                        </div>
                                        <SubmodulePanel />
                                    </div>
                                )}
                                {leftMenuSection === "guide" && (
                                    <div className="p-3">
                                        <div className="mb-3 text-xl">
                                            Guide
                                        </div>
                                        <UserGuide />
                                    </div>
                                )}
                            </div>
                            <div
                                className="model-builder-center-panel w-full"
                                style={{
                                    width: `calc(100% - ${
                                        modelBuilderSizes.toolbar
                                    } - ${
                                        isLeftMenuOpen
                                            ? modelBuilderSizes.leftMenu
                                            : "0px"
                                    } - ${
                                        isRightMenuOpen
                                            ? modelBuilderSizes.rightMenu
                                            : "0px"
                                    })`,
                                    transition: "all .25s ease",
                                }}
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        height: `calc(100vh - ${modelBuilderSizes.toolbar} - ${modelBuilderSizes.toolbar})`,
                                        minHeight: `calc(100vh - ${modelBuilderSizes.toolbar} - ${modelBuilderSizes.toolbar})`,
                                        alignItems: "center",
                                        display: "flex",
                                        flexDirection: "column",
                                        transition: "all .25s ease",
                                        overflow: "auto",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "100%",
                                            maxWidth: "1290px",
                                            margin: "0 auto",
                                            minWidth: "300px",
                                        }}
                                    >
                                        {section === "dashboard" && (
                                            <div className="p-3">
                                                <ModelBuilderDashboard />
                                            </div>
                                        )}
                                        {section === "design" && (
                                            <div className="p-3">
                                                <NestedDataList
                                                    toggleModelBlockAccordionKey={
                                                        toggleModelBlockAccordionKey
                                                    }
                                                    getIsModelBlockExpanded={
                                                        getIsModelBlockExpanded
                                                    }
                                                />
                                            </div>
                                        )}
                                        {section === "structure" && (
                                            <div className="p-3">
                                                <VariableOrganization />
                                            </div>
                                        )}
                                        {section === "connections" && (
                                            <div className="p-3">
                                                <QuestionList
                                                    simulationId={simulation.id}
                                                    selectedBuilderRoundId={
                                                        selectedBuilderRoundId
                                                    }
                                                    setSelectedBuilderRoundId={
                                                        setSelectedBuilderRoundId
                                                    }
                                                    setQuestionSubmoduleData={
                                                        setQuestionSubmoduleData
                                                    }
                                                />
                                            </div>
                                        )}
                                        {section === "submodules" && (
                                            <div className="p-3">
                                                <ModelTrees
                                                    simulationId={simulation.id}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div
                                    className="model-builder-breadcrumb-menu"
                                    style={{
                                        height: modelBuilderSizes.toolbar,
                                        minHeight: modelBuilderSizes.toolbar,
                                        position: "sticky",
                                        width: "100%",
                                        bottom: 0,
                                    }}
                                >
                                    <div
                                        className="px-3 text-sm"
                                        style={{
                                            height: "100%",
                                            alignItems: "center",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            background:
                                                modelBuilderColors.bgMenu,
                                            color: modelBuilderColors.menuColor,
                                        }}
                                    ></div>
                                </div>
                            </div>
                            <div
                                className="model-builder-right-detail-menu"
                                style={{
                                    height: "100%",
                                    width: isRightMenuOpen
                                        ? modelBuilderSizes.rightMenu
                                        : "0px",
                                    minWidth: isRightMenuOpen
                                        ? modelBuilderSizes.rightMenu
                                        : "0px",
                                    background: modelBuilderColors.bgPanel,
                                    color: modelBuilderColors.menuColor,
                                    overflow: "auto",
                                    transition: "all .25s ease",
                                }}
                            >
                                {rightMenuSection === "workspace" && (
                                    <div className="p-3">
                                        <div className="mb-3 text-xl">
                                            Workspace
                                        </div>
                                        <BuilderWorkspace />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div
                            className="model-builder-bottom flex w-full flex-col items-center overflow-auto"
                            style={{
                                height: `calc(100vh - ${modelBuilderSizes.toolbar})`,
                                background: modelBuilderColors.bgPanel,
                                color: modelBuilderColors.menuColor,
                            }}
                        >
                            <div className="w-full p-8">
                                {interactiveBlocks !== undefined && (
                                    <InteractiveBlockList
                                        simulation={simulation}
                                        modelBlocks={interactiveBlocks}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <ModelVariableForm />
                {!!questionSubmoduleData && (
                    <QuestionSubmoduleForm
                        questionSubmoduleData={questionSubmoduleData}
                        setQuestionSubmoduleData={setQuestionSubmoduleData}
                    />
                )}
            </>
        )
    );
}
