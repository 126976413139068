import React from "react";
import { Link as InertiaLink } from "@inertiajs/react";
import route from "ziggy-js";
import { ModelBlock, Simulation } from "../../models";
import { plus } from "react-icons-kit/feather/plus";
import { PrimaryButtonOutline } from "../../components/admin-components";
import { SortableModelBlockTree } from "./SortableModelBlockTree";
import { SapienInertia } from "@/inertia-utils/hooks";

export const InteractiveBlockList = (props: {
    simulation: Simulation;
    modelBlocks: ModelBlock[];
}) => {
    const { simulation, modelBlocks } = props;

    return (
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-3">
            <div>
                <div className="mb-4">
                    <div className="text-l mb-2 font-bold">
                        Interactive Blocks
                    </div>
                    {modelBlocks?.map((block, i) => (
                        <div
                            key={block.id}
                            className="p-2 hover:bg-neutral-800"
                        >
                            <InertiaLink
                                href={route(
                                    "admin.projects.model-builder.interactive-detail",
                                    {
                                        simulationSlug: simulation.slug,
                                        type: "interactive",
                                        interactiveBlockId: block.id,
                                    },
                                )}
                            >
                                <div className="px-3 font-light">
                                    {block.label}
                                </div>
                            </InertiaLink>
                        </div>
                    ))}
                </div>
                <div className="">
                    <PrimaryButtonOutline
                        text={"Create Interactive Block"}
                        icon={plus}
                        handler={() =>
                            SapienInertia.post(
                                "admin.model-blocks.store",
                                {
                                    label: "Interactive Block",
                                    simulation_id: simulation.id,
                                    weight: modelBlocks?.length || 0,
                                    is_interactive: true,
                                },
                                {},
                                {
                                    preserveState: true,
                                    preserveScroll: true,
                                },
                            )
                        }
                    />
                </div>
            </div>
            <div>
                <div className="text-l mb-4 font-bold">Organization</div>
                <SortableModelBlockTree
                    modelBlocks={modelBlocks?.reduce(
                        (map, block) => ({ ...map, [block.id]: block }),
                        {},
                    )}
                    modelBlockArray={modelBlocks as unknown as ModelBlock[]}
                    preventMixedLevelSort={true}
                />
            </div>
            <div></div>
        </div>
    );
};
